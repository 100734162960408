<template>
    <datepicker name="date" :inline="false" v-model="selected" placeholder="Select Date">
    </datepicker>
</template>
<script>
import Datepicker from "vuejs-datepicker";
export default {
    components: {
        Datepicker,
    },
    data() {
        return {
            selected: null
        };
    },
    methods: {

    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        }
    },
    mounted() {
    }
};
</script>