<template>
    <vx-card title="Handover Data Edit">
        <div class="vx-row" style="width: 100%">
            <div class="vx-col sm:w-1/2">
                <div class="vx-row mb-6" style="width: 100%" v-if="readonly">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Shipment Doc Number</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="pickingListNumber" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 100%" v-if="readonly">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Handover Document</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="pickingListNumber" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 100%" v-if="readonly">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Vehicle Number</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="pickingListNumber" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 100%" v-if="readonly">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Driver</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="pickingListNumber" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
            </div>
            <div class="vx-col sm:w-1/2">
                <div class="vx-row mb-6" style="width: 100%" v-if="readonly">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span># of Delivery Note</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="pickingListNumber" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 100%" v-if="readonly">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span># of COD</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <vs-input :value="pickingListNumber" :readonly="true" class="w-full"></vs-input>
                    </div>
                </div>
                <div class="vx-row mb-6" style="width: 100%">
                    <div class="vx-col sm:w-1/3 w-full flex items-center">
                        <span>Handover Date</span>
                    </div>
                    <div class="vx-col sm:w-2/3 w-full">
                        <Date></Date>
                    </div>
                </div>
            </div>
        </div>
        <br>
        <div>
            <h3>Loading Item</h3>
        </div>
        <vs-table stripe border :data="table.data">
            <template slot="thead">
                <vs-th sort-key="">SKU Code</vs-th>
                <vs-th sort-key="">SKU Name</vs-th>
                <vs-th sort-key="">HU</vs-th>
                <vs-th sort-key="">Qty</vs-th>
                <vs-th>Handed-over</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>{{ tr.SKUCode }}</vs-td>
                    <vs-td>{{ tr.SKUName }}</vs-td>
                    <vs-td>{{ tr.HU }}</vs-td>
                    <vs-td>{{ tr.Quantity }}</vs-td>
                    <vs-td>
                        <vs-checkbox
                            :checked="checked.includes(data[indextr].ID)"
                            v-on:click="addChecked(data[indextr].ID)"
                        ></vs-checkbox>
                    </vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <br>
        <div>
            <h3>Customer Return Pickup</h3>
        </div>
        <vs-table stripe border :data="table.data">
            <template slot="thead">
                <vs-th sort-key="">Customer Return Data</vs-th>
                <vs-th sort-key="">Pickup Document</vs-th>
                <vs-th sort-key="">Customer Data</vs-th>
                <vs-th sort-key="">Ship-to-Data</vs-th>
            </template>

            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>{{ tr.SKUCode }}</vs-td>
                    <vs-td>{{ tr.expDate }}</vs-td>
                    <vs-td>{{ tr.storageArea }}</vs-td>
                    <vs-td>{{ tr.batchNumber }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <br>
        <div class="mb-6 space-x-1 flex">
            <div class="">
                <vs-button color="primary" @click="handleSave">Save</vs-button>
            </div>
        </div>
    </vx-card>
</template>

<script>

import Date from "./component/Date.vue";
import moment from "moment";

export default {
    components: {
        Date,
    },
    props: {
        baseUrl: {
            type: String,
        },
        draw: {
            type: Number,
        },
    },
    data() {
        return {
            readonly: true,
            table: this.tableDefaultState(),
            pickingListNumber: null,
            checkedAll: false,
            checked: [],
        }
    },
    methods: {
        tableDefaultState() {
            return {
                data: [{
                    SKUCode: "89898989",
                    SKUName: "ULI JONGGOL",
                    HU: "uwowowow",
                    Quantity: "98246",
                    storageArea: "MW9NF9P",
                    batchNumber: "823748280374",
                    expDate: "24 JANUARI 2027",
                }],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get(this.baseUrl + "/table", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        // ...
                        date: moment(this.dateNow).format("YYYY-MM-DD"),
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        // this.table.total = resp.data.total_record;
                        // this.table.totalPage = resp.data.total_page;
                        // this.table.totalSearch = resp.data.total_record_search;
                        // this.table.length = resp.data.total_record_per_page;
                        // this.table.data = resp.data.records;
                        this.table.total = 1;
                        this.table.totalPage = 1;
                        this.table.totalSearch = 1;
                        this.table.length = 1;
                        this.table.data = [{
                            SKUCode: "89898989",
                            SKUName: "ULI JONGGOL",
                            HU: "uwowowow",
                            Quantity: "8055555",
                            storageArea: "MW9NF9P",
                            batchNumber: "823748280374",
                            expDate: "24 JANUARI 2027",
                        }];
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                });
        },
        handleSave() {
            window.history.back();
        },
    },
    mounted() {
        this.getData();
    },
    watch: {
        detail() {
            this.getData();
        },
        checkedAll(val) {
            if (val) {
                this.selectedRouteAssignmentToAssign = [];
                this.table.data.forEach((el, index) => {
                    this.checked[index] = true;
                    this.selectedRouteAssignmentToAssign.push(el);
                });
            } else {
                if (
                    this.selectedRouteAssignmentToAssign.length == this.checked.length
                ) {
                    this.checked.forEach((el, index) => {
                        this.checked[index] = false;
                    });
                    this.selectedRouteAssignmentToAssign = [];
                }
            }
        },
    },
}
</script>